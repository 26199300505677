import { StaticImage } from "gatsby-plugin-image";
import React, { useRef } from "react";
import "twin.macro";
import { Navbar, SEO } from "../components";
import { LocationBlock } from "../components/common";
import { HearingAidFeatures } from "../components/pages/hearingAidBrands";
import { HearingAidBrands } from "../components/pages/indexPage";
import { useOpaqueNavbar } from "../utils";

const HearingAidBrandsPage = () => {
  const bgImageRef = useRef(null);
  const navbarOpaque = useOpaqueNavbar(bgImageRef);
  return (
    <>
      <SEO title="Hearing Aid Brands - The Latest Hearing Technology" />
      <Navbar opaque={navbarOpaque} />
      <section>
        <div tw="grid w-full" ref={bgImageRef}>
          <StaticImage
            src="../images/hearing-aid-brands-hero.jpg"
            alt=""
            layout="fullWidth"
            placeholder="blurred"
            loading="eager"
            tw="h-screen grid-area[1/1]"
            quality={90}
          />
          <div tw="absolute top-0 w-full h-full bg-black/20" />
          <div tw="grid grid-area[1/1] place-items-center px-4 z-10">
            <h1 tw="mb-10 text-center text-white uppercase font-display text-6xl letter-spacing[2.5px] md:(text-8xl letter-spacing[4px]) text-shadow[0px 0px 20px rgb(0 0 / 55%)] line-height[1.25]">
              HEARING AID BRANDS
            </h1>
          </div>
        </div>
      </section>
      <main>
        <section aria-label="Hearing Aid Brands intro" tw="bg-white">
          <div tw="mx-auto max-w-screen-xl pt-20 px-4 md:px-8">
            <div tw="space-y-6 md:(space-x-12 space-y-0 flex) font-light">
              <div tw="md:flex-basis[50%] text-2xl space-y-5 leading-snug">
                <p>
                  As an independent audiology centre, we are able to prescribe
                  and provide hearing aids from any manufactures. This ensures
                  we can recommend and provide a product to suit all needs,
                  lifestyles and budgets.
                </p>
                <p>
                  At The Ear Place we offer everybody a complimentary hearing
                  aid trial, no upfront cost, no obligation to purchase, just a
                  chance for you to experience how hearing aids can enhance or
                  change lives.
                </p>
              </div>
              <div tw="flex-basis[50%] space-y-5 leading-relaxed">
                <p>
                  Hearing aids are available in different shapes, styles, sizes
                  and colours with a range of features - the size of the hearing
                  aid does not dictate the cost of it. During the consultation a
                  decision is reached on which hearing aids are most suitable
                  based on a number of factors including the hearing test
                  results, personal preferences and lifestyle.
                </p>
                <p>
                  We will always provide the latest hearing aid technology from
                  leading manufactures. Hearing aids can be simple, automatic
                  and require minimal interaction from the wearer, or hearing
                  aids can be advanced devices with several functions and
                  features for the wearer to utilise and enjoy.
                </p>
                <p>
                  At The Ear Place we pride ourselves on our aftercare service
                  to ensure your hearcare needs are looked after continuously.
                  We also offer a range of care packages to choose from
                  including hearing aid rental plans meaning new hearing aids
                  seamlessly every 3 years!
                </p>
              </div>
            </div>
            <StaticImage
              src="../images/hearing-aid-example.jpg"
              alt="Example hearing aids"
              tw="w-full mt-12 md:mt-24"
            />
            <HearingAidBrands text="OUR BRANDS" full={false} />
            <div tw="my-12">
              <HearingAidFeatures />
            </div>
          </div>
        </section>
        <LocationBlock />
      </main>
    </>
  );
};

export default HearingAidBrandsPage;
