import React from "react";
import tw from "twin.macro";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { StaticImage } from "gatsby-plugin-image";

import Rechargeable from "../../../images/hearing-aid-features/rechargeable.svg";
import Bluetooth from "../../../images/hearing-aid-features/bluetooth.svg";
import Compatible from "../../../images/hearing-aid-features/phone-compatible.svg";
import Ittt from "../../../images/hearing-aid-features/ittt.svg";
import Find from "../../../images/hearing-aid-features/find-my-hearing-aid.svg";
import Call from "../../../images/hearing-aid-features/call-to-text.svg";
import Active from "../../../images/hearing-aid-features/active-learning.svg";

import { hearingAidFeatures } from "../../../config";

const images: { [key: string]: EmotionJSX.Element } = {
  Rechargeable: <Rechargeable tw="max-h-24 w-full" />,
  Bluetooth: <Bluetooth tw="max-h-24 w-full" />,
  Compatible: <Compatible tw="max-h-24 w-full" />,
  Ittt: <Ittt tw="max-h-24 w-full" />,
  Find: <Find tw="max-h-24 w-full" />,
  Call: <Call tw="max-h-24 w-full" />,
  Active: <Active tw="max-h-24 w-full" />,
};

const HearingAidFeatures = () => {
  return (
    <>
      <section aria-label="Hearing Aid Features">
        <div tw="max-w-screen-xl mx-auto text-center">
          <StaticImage
            src="../../../images/hearing-aid-components.jpg"
            alt="Hearing aid components"
            tw="w-full"
          />
          <h3 tw="text-[40px] text-center leading-tight my-16 font-display">
            KEY FEATURES
          </h3>
          <div tw="flex flex-wrap justify-center items-stretch">
            {hearingAidFeatures.map(({ label, text, imageKey }, idx) => (
              <div
                key={label}
                tw="flex-basis[100%] md:flex-basis[50%] xl:flex-basis[33%] px-4 py-8 md:p-8 flex flex-col items-center"
                css={[
                  idx === hearingAidFeatures.length - 1 &&
                    tw`md:flex-basis[75%]! lg:flex-basis[55%]!`,
                ]}
              >
                {images[imageKey]}
                <h4 tw="mb-5 mt-10 font-display font-normal letter-spacing[2.5px] text-3xl leading-snug text-sonic text-center uppercase">
                  {label}
                </h4>
                <p tw="font-light text-center">{text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default HearingAidFeatures;
